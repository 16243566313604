import { Component, OnInit } from '@angular/core';
import { doc, getDoc } from '@firebase/firestore';
import { firestore } from 'src/firebase';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  // user object
  user: any;

  constructor() { }

  async getUserDetails(user_id: string) {
    try {
      // get user details from firestore
      const userDoc = await getDoc(doc(firestore, 'users', user_id));
      this.user = userDoc.data();
      this.user.id = user_id;
    } catch (error) {
      console.log(error);
    }
  }

  ngOnInit(): void  {
  }
}