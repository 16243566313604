<div class="box">
    <div class="h5 pr-2 pl-2">Folgende Schüler bieten in diesem Fach Nachhilfe an:</div>
    <mat-form-field class="pl-2 pr-2">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="filterDataSource($event)" placeholder="Schüler suchen...">
    </mat-form-field>
    <div class="tablecontainer mat-elevation-z8 ml-2 mr-2">
        <table mat-table [dataSource]="dataSource">
            <!-- Display student -->
            <!-- Student name -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let student">{{student.name}} ({{student.class}})</td>
            </ng-container>
    
            <!-- Student phone number -->
            <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef>Telefonnummer</th>
                <td mat-cell *matCellDef="let student"><a [href]="'tel:' + student.phone"><u>{{student.phone}}</u></a></td>
            </ng-container>
    
            <!-- Years -->
            <ng-container matColumnDef="years">
                <th mat-header-cell *matHeaderCellDef>Jahrgangsstufen</th>
                <td mat-cell *matCellDef="let student">{{student.years}}</td>
            </ng-container>
    
            <!-- Student wage -->
            <ng-container matColumnDef="wage">
                <th mat-header-cell *matHeaderCellDef>Stundenlohn</th>
                <td mat-cell *matCellDef="let student">{{student.wage}} €</td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <a class="text-dark link" [routerLink]="['/fach']" routerLinkActive="router-link-active">ZURÜCK</a>
</div>
