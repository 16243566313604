import { Component, OnInit } from '@angular/core';
import { FirebaseAuthService } from './shared/services/firebase-auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private firebaseAuth: FirebaseAuthService) {}

  // scroll to top at every new component
  onActivate() {
    window.scroll(0, 0);
  }

  async ngOnInit() {
    // check auth state
    await this.firebaseAuth.checkLoggedIn();
  }
}
