import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// component
import { LoginComponent } from './shared/components/login/login.component';
import { NavComponent } from './shared/components/nav/nav.component';
import { ExtraLessonsPortalHomeComponent } from './extra-lessons-portal/components/extra-lessons-portal-home/extra-lessons-portal-home.component';
import { ExtraLessonsPortalStudentsComponent } from './extra-lessons-portal/components/extra-lessons-portal-students/extra-lessons-portal-students.component';
import { ExtraLessonsPortalSubjectsComponent } from './extra-lessons-portal/components/extra-lessons-portal-subjects/extra-lessons-portal-subjects.component';
import { ExtraLessonsPortalOfferLessonsComponent } from './extra-lessons-portal/components/extra-lessons-portal-offer-lessons/extra-lessons-portal-offer-lessons.component';
import { ImpressumComponent } from './shared/components/impressum/impressum.component';
import { DatenschutzComponent } from './shared/components/datenschutz/datenschutz.component';
import { AdminPortalComponent } from './admin-portal/components/admin-portal/admin-portal.component';

// services
import { AdminPortalAuthGuardService } from './admin-portal/services/admin-portal-auth-guard.service';
import { UserPortalAuthGuardService } from './user-portal/services/user-portal-auth-guard.service';

const routes: Routes = [
  { path: '', component: NavComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: '', component: ExtraLessonsPortalHomeComponent, canActivate: [UserPortalAuthGuardService] },
      { path: 'fach', component: ExtraLessonsPortalSubjectsComponent, canActivate: [UserPortalAuthGuardService] },
      { path: 'fach/:subject_id', component: ExtraLessonsPortalStudentsComponent, canActivate: [UserPortalAuthGuardService] },
      { path: 'angebote', component: ExtraLessonsPortalOfferLessonsComponent, canActivate: [UserPortalAuthGuardService] },
      { path: 'admin', component: AdminPortalComponent, canActivate: [AdminPortalAuthGuardService] },
    ]
  },
  { path: 'impressum', component: ImpressumComponent },
  { path: 'datenschutz', component: DatenschutzComponent },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
