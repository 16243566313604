import { Subject } from "src/app/extra-lessons-portal/models/subject";

export const SUBJECTS: Subject[] = [
    {
        id: 'deutsch',
        name: 'Deutsch'
    },
    {
        id: 'englisch',
        name: 'Englisch'
    },
    {
        id: 'mathe',
        name: 'Mathe'
    },
    {
        id: 'latein',
        name: 'Latein'
    },
    {
        id: 'chemie',
        name: 'Chemie'
    },
    {
        id: 'physik',
        name: 'Physik'
    },
    {
        id: 'informatik',
        name: 'Informatik'
    },
    {
        id: 'franzoesisch',
        name: 'Französisch'
    },
    {
        id: 'biologie',
        name: 'Biologie'
    },
    {
        id: 'musik',
        name: 'Musik'
    },
    {
        id: 'wirtschaft',
        name: 'Wirtschaft und Recht'
    }
];