<form #addExtraLessonOfferNgForm="ngForm" [formGroup]="addExtraLessonOfferForm" (ngSubmit)="onFormSubmit(addExtraLessonOfferNgForm)">

    <!-- Subject of extra lesson offer -->
    <mat-form-field appearance="outline">
        <mat-label>Fach</mat-label>
        <mat-select formControlName="subject" [errorStateMatcher]="matcher">
            <mat-option *ngFor="let subject of subjects" [value]="subject.id">{{subject.name}}</mat-option>
        </mat-select>
        <mat-error>
            <span *ngIf="!addExtraLessonOfferForm.get('subject').valid && addExtraLessonOfferForm.get('subject').touched">Bitte Fach auswählen</span>
        </mat-error>
    </mat-form-field>

    <!-- Years of extra lesson offer -->
    <mat-form-field appearance="outline">
        <mat-label>Klassenstufen</mat-label>
        <mat-select formControlName="years" [errorStateMatcher]="matcher" multiple>
            <mat-option *ngFor="let year of years" [value]="year">{{year}}</mat-option>
        </mat-select>
        <mat-error>
            <span *ngIf="!addExtraLessonOfferForm.get('years').valid && addExtraLessonOfferForm.get('years').touched">Bitte Klassenstufen auswählen</span>
        </mat-error>
    </mat-form-field>

    <!-- Phone number of student -->
    <mat-form-field appearance="outline">
        <mat-label>Deine Telefonnummer</mat-label>
        <input matInput placeholder="Bsp. +49 172 93874823" formControlName="phone" [errorStateMatcher]="matcher">
        <mat-error>
            <span *ngIf="!addExtraLessonOfferForm.get('phone').valid && addExtraLessonOfferForm.get('phone').touched">Bitte Telefonnummer eingeben</span>
        </mat-error>
    </mat-form-field>

    <!-- Wage of lsp -->
    <mat-form-field appearance="outline">
        <mat-label>Stundenlohn (min. 12 €/Stunde)</mat-label>
        <input matInput type="number" min="12" formControlName="wage" [errorStateMatcher]="matcher">
        <mat-error>
            <span *ngIf="!addExtraLessonOfferForm.get('wage').valid && addExtraLessonOfferForm.get('wage').touched">Bitte Stundenlohn eingeben</span>
        </mat-error>
    </mat-form-field>

    <!-- Submit button and messages -->
    <button class="mb-3 py-1" mat-raised-button type="submit" [disabled]="!addExtraLessonOfferForm.valid || isLoading" color="primary">Nachhilfe anbieten</button>
    <p class="loading" *ngIf="isLoading">Nachhilfeangebot wird erstellt...</p>
    <p class="success" *ngIf="successMessage">{{ successMessage }}</p>
    <p class="error" *ngIf="errorMessage">{{ errorMessage }}</p>
</form>