import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AdminPortalAuthGuardService {

  constructor(private router: Router) { }

  // can activate function for admin user protected routes
  canActivate() {
    // get user from local storage
    const user = localStorage.getItem('user');
    // check if admin user is logged in
    if (JSON.parse(user) == null || JSON.parse(user).role !== 'admin') {
      // redirect to login
      this.router.navigate(['login']);
      return false;
    } else {
      return true;
    }
  }
}
