import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { collection, doc, getDoc, query } from '@firebase/firestore';
import { getDocs, where } from 'firebase/firestore';
import { applyFilter } from 'src/app/shared/functions/tableFiltering';
import { firestore } from 'src/firebase';

@Component({
  selector: 'app-extra-lessons-portal-students',
  templateUrl: './extra-lessons-portal-students.component.html',
  styleUrls: ['./extra-lessons-portal-students.component.scss']
})
export class ExtraLessonsPortalStudentsComponent implements OnInit {

  // angular material table setup
  displayedColumns: string[] = ['name', 'phone', 'years', 'wage'];
  dataSource = new MatTableDataSource();

  students = [];

  constructor(private route: ActivatedRoute) { }

  async getStudents(subject_id: string) {
    // clear student data
    this.students = [];
    try {
      const q = query(collection(firestore, 'extra_lesson_offers'), where('subject', '==', subject_id));
      const studentDocs = await getDocs(q);
      // format docs
      studentDocs.forEach(async (tutorDoc: any) => {
        const data = tutorDoc.data();
        this.students.push({
          name: data.user_name,
          class: data.user_class,
          phone: data.phone,
          years: data.years.toString(),
          wage: data.wage
        });
      });
      // create data source from students array
      this.dataSource = new MatTableDataSource(this.students);
    } catch (error) {
      console.log(error);
    }
  }

  extract() {
    // get students from firestore
    this.getStudents(this.route.snapshot.params.subject_id);
  }

  ngOnInit(): void {
    this.extract();
  }

  // table filtering
  filterDataSource(event: Event) {
    this.dataSource.filter = applyFilter(event);
  }
}
