<mat-card class="py-4 px-5">
    <div class="h5 mt-3 mx-3 pb-3">In das Nachhilfeportal einloggen.</div>
    <form class="mx-3 mb-3" [formGroup]="loginForm" (ngSubmit)="onFormSubmit()">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Portal-ID</mat-label>
        <input type="text" matInput formControlName="portal_id" [errorStateMatcher]="matcher">
        <mat-error>
          <span *ngIf="!loginForm.get('portal_id').valid && loginForm.get('portal_id').touched">Bitte Portal-ID eingeben.</span>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Passwort</mat-label>
        <input type="password" matInput formControlName="password" [errorStateMatcher]="matcher">
        <mat-error>
          <span *ngIf="!loginForm.get('password').valid && loginForm.get('password').touched">Bitte Passwort eingeben.</span>
        </mat-error>
      </mat-form-field>
      <button class="w-100 mt-1 py-1" mat-raised-button type="submit" [disabled]="(!loginForm.valid || isLoading)" color="primary">Einloggen</button>
      <p *ngIf="errorMessage" class="error mt-3 text-center pt-0" [innerText]="errorMessage"></p>
    </form>
    <div class="mx-3 mt-5 mb-1 headings">Datenschutz:</div>
    <p class="mx-3 mb-3 datenschutz">Das Pater-Rupert-Mayer-Gymnasium nimmt den Schutz von Schülerdaten ernst, weswegen keinerlei Daten von Schülerinnen und Schülern erhoben und an Dritte übermittelt werden, die nicht unbedingt notwendig sind. Im Rahmen des Nachilfeportals müssen Name und Vorname sowie Klasse potentielle Nachilfeschüler:innen oder Nachhilfeanbieter:innen übermittelt werden. Mit der Anmeldung im Portal willigen Sie ein, dass das Pater-Rupert-Mayer-Gymnasium berechtigt ist, die erforderlichen Daten zu erheben und an die betreffenden Personen zu übermitteln. Die Einwilligung kann jederzeit widerrufen werden, was die Abmeldung vom Nachhilfeportal zur zur Folge hat.</p>
</mat-card>