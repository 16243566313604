import { MyErrorStateMatcher } from './../../../shared/functions/errorStateMatcher';
import { YEARS } from './../../../shared/data/years';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, NgForm, Validators } from '@angular/forms';
import { SUBJECTS } from 'src/app/shared/data/subjects';
import { getDoc, doc, query } from '@firebase/firestore';
import { auth, firestore } from 'src/firebase';
import { addDoc, collection, setDoc, where } from 'firebase/firestore';
import { NavComponent } from 'src/app/shared/components/nav/nav.component';

@Component({
  selector: 'app-extra-lessons-portal-add-lesson-offer',
  templateUrl: './extra-lessons-portal-add-lesson-offer.component.html',
  styleUrls: ['./extra-lessons-portal-add-lesson-offer.component.scss']
})
export class ExtraLessonsPortalAddLessonOfferComponent implements OnInit {

  // form variable
  addExtraLessonOfferForm: any;

  // subject selection
  subjects = SUBJECTS;

  // year selection
  years = YEARS;

  // success and error messages variables
  successMessage: string = '';
  errorMessage: string = '';

  // error state matcher for form
  matcher = new MyErrorStateMatcher();

  // loading variable to prevent duplicated api requests
  isLoading: boolean = false;

  constructor(private formBuilder: FormBuilder, private navComponent: NavComponent) { }

  async getUserDetails(user_id: string) {
    try {
      // get user details from firestore
      const userDoc = await getDoc(doc(firestore, 'users', user_id));
      let user = userDoc.data();
      user.id = userDoc.id;

      return user;
    } catch (error) {
      console.log(error);
    }
  }

  setupForm() {
    this.addExtraLessonOfferForm = this.formBuilder.group({
      subject: [null, Validators.required],
      years: [null, Validators.required],
      phone: [null, Validators.required],
      wage: [null, [Validators.required, Validators.min(12)]]
    });
  }

  ngOnInit(): void {
    // setup form
    this.setupForm();
  }

  async addExtraLessonOffer(extra_lesson_offer: any, form: NgForm) {
    try {
      // save offer to firestore
      await addDoc(collection(firestore, 'extra_lesson_offers'), extra_lesson_offer);
      // display success
      this.successMessage = 'Nachhilfeangebot erstellt';
      // clear form
      this.clearForm(form);
    } catch (error) {
      console.log(error);
      // display error
      this.errorMessage = 'Fehler beim Erstellen des Nachhilfeangebots.';
    }
  }

  async onFormSubmit(form: NgForm) {
    // enable loading
    this.isLoading = true;

    // clear messages
    this.successMessage = this.errorMessage = '';

    // retrieve form data
    let extra_lesson_offer = this.addExtraLessonOfferForm.value;

    // get current users details
    const user = await this.getUserDetails(auth.currentUser.uid);

    // add student user id, portal_id, name and class to offer
    extra_lesson_offer.user_id = user.id;
    extra_lesson_offer.user_portal_id = user.portal_id;
    extra_lesson_offer.user_name = user.name;
    extra_lesson_offer.user_class = user.class;

    // add legal service provider to firestore
    await this.addExtraLessonOffer(extra_lesson_offer, form);

    // disable loading
    this.isLoading = false;
  }
  // reset and clear form
  clearForm(form: NgForm) {
    form.form.markAsPristine();
    form.resetForm();
  }
}
