import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { onSnapshot, doc, Unsubscribe, updateDoc } from 'firebase/firestore';
import { CLASSES } from 'src/app/shared/data/classes';
import { ROLES } from 'src/app/shared/data/roles';
import { firestore } from 'src/firebase';


@Component({
  selector: 'app-admin-portal-user-edit-dialog',
  templateUrl: './admin-portal-user-edit-dialog.component.html',
  styleUrls: ['./admin-portal-user-edit-dialog.component.scss']
})
export class AdminPortalUserEditDialogComponent implements OnInit {

  user: any;

  // form variable
  editUserForm: any;

  // classes and roles
  classes = CLASSES;
  roles = ROLES;

  // loading animatation indicatar indicator variable
  isLoading: boolean = false;

  // unsubscribs
  unsubscribs: Unsubscribe;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  getUser() {
    try {
      // listen to realtime updates
      this.unsubscribs = onSnapshot(doc(firestore, 'users', this.data.id), (doc) => {
        this.user = doc.data();
      });
    } catch (error) {
      this.unsubscribs();
      console.log(error);
    }
  }

  ngOnInit() {
    // enable loading
    this.isLoading = true;

    // start real time snapshot of user
    this.getUser();

    // enable loading
    this.isLoading = false;
  }

  async updateUser() {
    try {
      // enable loading
      this.isLoading = true;

      // update doc of user
      await updateDoc(doc(firestore, 'users', this.data.id), {
        name: this.user.name,
        class: this.user.class
      });

      // enable loading
      this.isLoading = false;
    } catch (error) {
      console.log(error);
    }
  }
}
