<div class="tablecontainer mat-elevation-z8 mt-3">
  <table mat-table [dataSource]="usersDataSource">
    <!-- Display users -->

    <!-- Poral-ID of user -->
    <ng-component matColumnDef="portal_id">
      <th mat-header-cell *matHeaderCellDef [innerHTML]="'Portal-ID'"></th>
      <td mat-cell *matCellDef="let user">{{user.portal_id}}</td>
    </ng-component>

    <!-- Name of user -->
    <ng-component matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef [innerHTML]="'Name'"></th>
      <td mat-cell *matCellDef="let user">{{user.name}}</td>
    </ng-component>

    <!-- Class of user -->
    <ng-component matColumnDef="class">
      <th mat-header-cell *matHeaderCellDef [innerHTML]="'Klasse / Personal'"></th>
      <td mat-cell *matCellDef="let user">{{user.class}}</td>
    </ng-component>

    <!-- Role of user -->
    <ng-component matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef [innerHTML]="'Rolle'"></th>
      <td mat-cell *matCellDef="let user">
        <div *ngIf="user.role == roles[0].id" [innerHTML]="roles[0].name"></div>
        <div *ngIf="user.role == roles[1].id" [innerHTML]="roles[1].name"></div>
      </td>
    </ng-component>

    <!-- Edit of user -->
    <ng-component matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef [innerHTML]="'Editieren'"></th>
      <td mat-cell *matCellDef="let user">
        <button mat-icon-button (click)="openDialogEdit(user.id)">
          <mat-icon aria-hidden="false" color="primary">edit</mat-icon>
        </button>
      </td>
    </ng-component>

    <!-- Change password of user -->
    <ng-component matColumnDef="changePassword">
      <th mat-header-cell *matHeaderCellDef [innerHTML]="'Passwort ändern'"></th>
      <td mat-cell *matCellDef="let user">
        <button mat-icon-button (click)="openDialogChangePassword(user.id)">
          <mat-icon aria-hidden="false" color="primary">lock</mat-icon>
        </button>
      </td>
    </ng-component>

    <!-- Delete of user -->
    <ng-component matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef [innerHTML]="'Löschen'"></th>
      <td mat-cell *matCellDef="let user">
        <button mat-icon-button >
          <mat-icon aria-hidden="false" (click)="openDialogDelete(user.id)" color="warn">delete</mat-icon>
        </button>
      </td>
    </ng-component>

    <tr mat-header-row *matHeaderRowDef="usersTableDisplayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: usersTableDisplayedColumns"></tr>
  </table>
</div>