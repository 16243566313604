import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { collection, onSnapshot, query, Unsubscribe } from 'firebase/firestore';
import { SUBJECTS } from 'src/app/shared/data/subjects';
import { firestore } from 'src/firebase';
import { AdminPortalExtraLessonOffersDeleteDialogComponent } from '../admin-portal-extra-lesson-offers-delete-dialog/admin-portal-extra-lesson-offers-delete-dialog.component';

@Component({
  selector: 'app-admin-portal-extra-lesson-offers',
  templateUrl: './admin-portal-extra-lesson-offers.component.html',
  styleUrls: ['./admin-portal-extra-lesson-offers.component.scss']
})
export class AdminPortalExtraLessonOffersComponent implements OnInit {
  
  offers: any;
  
  // loading animatation indicatar indicator variable
  isLoading: boolean = false;

  // angular material users table setup
  offersTableDisplayedColumns: string[] = ['portal_id', 'name', 'class', 'subject', 'years', 'phone', 'wage', 'delete'];
  offersDataSource = new MatTableDataSource();

  // unsubscribs
  unsubscribs: Unsubscribe;

  // subjcts
  subjects = SUBJECTS;

  constructor(private dialog: MatDialog) { }

  getOffers() {
    try {
      // define query
      const q = query(collection(firestore, 'extra_lesson_offers'));
      // listen to realtime updates
      this.unsubscribs = onSnapshot(q, (querySnapshot) => {
        this.offers = [];
        querySnapshot.forEach((doc) => {
          let offer = doc.data();
          offer.id = doc.id;
          // format subject
          offer.subject = this.subjects.find((x: any) => x.id === offer.subject).name;
          this.offers.push(offer);
        });
        // update users table
        this.offersDataSource = new MatTableDataSource(this.offers);
      });
    } catch (error) {
      this.unsubscribs();
      console.log(error);
    }
  }

  ngOnInit() {
    // enable loading
    this.isLoading = true;

    // start real time snapshot of offers
    this.getOffers();

    // enable loading
    this.isLoading = false;
  }

  ngOnDestroy(): void {
    this.unsubscribs();
  }

  openDialog(user_id: any) {
    this.dialog.open(AdminPortalExtraLessonOffersDeleteDialogComponent, {
      width: '400px',
      data: {
        id: user_id
      }
    });
  }
}
