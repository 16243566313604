import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { httpsCallable } from 'firebase/functions';
import { functions } from 'src/firebase';

@Component({
  selector: 'app-admin-portal-user-delete-dialog',
  templateUrl: './admin-portal-user-delete-dialog.component.html',
  styleUrls: ['./admin-portal-user-delete-dialog.component.scss']
})
export class AdminPortalUserDeleteDialogComponent implements OnInit {

  // firebase functions
  deleteUserAccount = httpsCallable(functions, 'deleteUser');

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  async removeUser() {
    // delete user
    await this.deleteUserAccount(this.data);
  }

  ngOnInit(): void {
  }

}
