<div class="text-dark">
  <div class="mb-1 h2" mat-dialog-title>Passwort ändern</div>
  <mat-dialog-content class="py-3">
    <form #changePasswordngForm="ngForm" [formGroup]="changePasswordForm" (ngSubmit)="onFormSubmit(changePasswordngForm)">
      <mat-form-field appearance="outline">
        <mat-label>Neues Passwort</mat-label>
        <input matInput minlength="8" placeholder="Passwort" formControlName="newPassword" [type]="hide ? 'password' : 'text'" [errorStateMatcher]="matcher">
        <button type="button" mat-icon-button  matSuffix (click)="hide = !hide" attr.aria-label="Hide password" [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error>
          <span *ngIf="!changePasswordForm.get('newPassword').valid && changePasswordForm.get('newPassword').touched">Bitte neues min. 8-stelliges Passwort eingeben</span>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Neues Passwort wiederholen</mat-label>
        <input matInput minlength="8" placeholder="Passwort" formControlName="newPassword2" [type]="hide ? 'password' : 'text'" [errorStateMatcher]="matcher">
        <button type="button" mat-icon-button  matSuffix (click)="hide = !hide" attr.aria-label="Hide password" [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error>
          <span *ngIf="!changePasswordForm.get('newPassword2').valid && changePasswordForm.get('newPassword2').touched">Bitte neues min. 8-stelliges Passwort eingeben</span>
        </mat-error>
      </mat-form-field>
      <button class="mt-3" type="submit" [disabled]="!changePasswordForm.valid" color="primary" mat-raised-button>Passwort ändern</button>
      <p class="loading text-center mt-3" *ngIf="isLoading">Passwort speichern...</p>
      <p class="success mt-3" *ngIf="successMessage">{{ successMessage }}</p>
      <p class="error mt-3" *ngIf="errorMessage">{{ errorMessage }}</p>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close>Zurück</button>
  </mat-dialog-actions>
</div>