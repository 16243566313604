import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { updatePassword } from '@firebase/auth';
import { auth } from 'src/firebase';
import { MyErrorStateMatcher } from '../../functions/errorStateMatcher';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {

  isLoading = false;
  successMessage: any;
  errorMessage: any;

  user: any;

  subscr = [];
  changePassword$: any;
  updateUser$: any;

  changePasswordForm: FormGroup;
  matcher = new MyErrorStateMatcher();
  hide: boolean = true;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group({
      newPassword : [null, Validators.required],
      newPassword2 : [null, Validators.required]
    });
  }

  async updateUserPassword(password: string, form: NgForm) {
    try {
      await updatePassword(auth.currentUser, password);
      // clear form
      form.resetForm();
      // display success
      this.successMessage = 'Passwort geändert.';
    } catch (error) {
      console.log(error);
      // display error
      this.errorMessage = 'Fehler beim Ändern des Passwortes. Bitte kontaktiere uns.';
    }
  }

  async onFormSubmit(form: NgForm) {
    // enable loading
    this.isLoading = true;

    // clear messages
    this.successMessage = this.errorMessage = '';

    if (this.changePasswordForm.value.newPassword !== this.changePasswordForm.value.newPassword2) {
      // passwords don't match
      this.errorMessage = 'Die beiden neuen Passwörter stimmen nicht überein.';
    } else {
      // update password
      await this.updateUserPassword(this.changePasswordForm.value.newPassword, form);
    }
    
    // deactivate loading
    this.isLoading = false;
  }
}
