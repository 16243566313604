import { Component, OnInit } from '@angular/core';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserPortalAuthGuardService } from 'src/app/user-portal/services/user-portal-auth-guard.service';
import { MyErrorStateMatcher } from '../../functions/errorStateMatcher';
import { environment } from 'src/environments/environment';
import { auth } from 'src/firebase';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  // form variable
  loginForm: any;

  // error state matcher for form
  matcher = new MyErrorStateMatcher();

  // message variables
  errorMessage: any;

  // prevent duplicated api requests
  isLoading: boolean = false;

  constructor(private router: Router, private formBuilder: FormBuilder, private userPortalAuthGuard: UserPortalAuthGuardService) { }

  // setup form
  setupForm() {
    this.loginForm = this.formBuilder.group({
      portal_id: [null, Validators.required],
      password: [null, Validators.required]
    });
  }

  ngOnInit(): void {
    // redirect user to home if logged in
    if (this.userPortalAuthGuard.canActivate()) {
      // user is logged in => redirect to home
      this.navigateTo('/')
    } else {
      // setup form
      this.setupForm();
    }
  }

  // login with credentials
  async login(email: string, password: string) {
    try {
      // sign in user
      await signInWithEmailAndPassword(auth, email, password)
      // wait 1s
      await new Promise(resolve => setTimeout(resolve, 10));
      // redirect case user to nachhilfeportal
      this.navigateTo('/')
    } catch (error) {
      console.log(error.code)
      // display error
      if (error.code === 'auth/user-not-found') {
        this.errorMessage = 'Portal-ID ist falsch.'
      } else if (error.code === 'auth/wrong-password') {
        this.errorMessage = 'Passwort ist falsch.'
      } else {
        this.errorMessage = error
      }
    }
  }

  async onFormSubmit() {
    // activate loading var
    this.isLoading = true;

    // retrieve form data
    let loginCredentials = this.loginForm.value;

    // format shop-id to email
    const portal_id = loginCredentials.portal_id + '@' + environment.emailDomain;

    // login user
    await this.login(portal_id, loginCredentials.password);

    // deactivate loading var if login failed
    this.isLoading = false;
  }

  // navigation
  navigateTo(url: string) {
    this.router.navigate([url]);
  }
}

