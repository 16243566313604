import { Injectable } from '@angular/core';
import { getAuth, onAuthStateChanged } from '@firebase/auth';
import { auth } from 'src/firebase';

@Injectable({
  providedIn: 'root'
})
export class FirebaseAuthService {

  constructor() { }

  getCurrentUser(auth: any) {
    return new Promise((resolve, reject) => {
       const unsubscribe = auth.onAuthStateChanged((user: any) => {
          unsubscribe();
          resolve(user.uid);
       }, reject);
    });
  }  

  // check if user is logged in
  async checkLoggedIn() {
    onAuthStateChanged(auth, async (user: any) => {
     if (user) {
       // safe user to local storage (session)
       await auth.currentUser.getIdTokenResult()
       .then((idTokenResult) => {
         localStorage.setItem('user', JSON.stringify(idTokenResult.claims));
       })
       .catch((error) => {
         console.log(error);
         localStorage.setItem('user', null);
       });
     } else {
       // user is not signed in
       localStorage.setItem('user', null);
     }
   });
  }
}
