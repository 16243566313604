import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { onAuthStateChanged } from '@firebase/auth';
import { signOut } from 'firebase/auth';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChangePasswordDialogComponent } from '../change-password-dialog/change-password-dialog.component';
import { NavComponent } from '../nav/nav.component';
import { auth } from 'src/firebase';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  loggedIn: boolean = false;
  year: number = new Date().getFullYear();

  constructor(private router: Router, private snackBar: MatSnackBar, private dialog: MatDialog, private navComponent: NavComponent) { }

  ngOnInit() {
    // check if user is logged in on every auth state change
    onAuthStateChanged(auth, async (user: any) => {
      if (user !== null) {
        this.loggedIn = true;
        this.navComponent.getUserDetails(user.uid);
      } else {
        this.loggedIn = false;
      }
    });
  }

  // display logout snackbar
  showSnackBar() {
    const snackBarRef = this.snackBar.open('Ausloggen?', 'Ja', {
      duration: 8000
    });
    snackBarRef.onAction().subscribe(() => {
      this.logout();
    })
  }

  // password dialog setup
  openDialogChangePassword() {
    this.dialog.open(ChangePasswordDialogComponent, {
      width: '400px'
    });
  }

  async logout() {
    try {
      await signOut(auth);
      // redirect user to login
      this.navigateTo('login');
    } catch (error) {
      console.log(error);
    }
  }

  // navigation
  navigateTo(url: string) {
    this.router.navigate([url]);
  }
}
