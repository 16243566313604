import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Component imports
import { AppComponent } from './app.component';
import { NavComponent } from './shared/components/nav/nav.component';
import { LoginComponent } from './shared/components/login/login.component';
import { ExtraLessonsPortalHomeComponent } from './extra-lessons-portal/components/extra-lessons-portal-home/extra-lessons-portal-home.component';
import { ExtraLessonsPortalSubjectsComponent } from './extra-lessons-portal/components/extra-lessons-portal-subjects/extra-lessons-portal-subjects.component';
import { ExtraLessonsPortalStudentsComponent } from './extra-lessons-portal/components/extra-lessons-portal-students/extra-lessons-portal-students.component'; 
import { ExtraLessonsPortalOfferLessonsComponent } from './extra-lessons-portal/components/extra-lessons-portal-offer-lessons/extra-lessons-portal-offer-lessons.component';
import { ExtraLessonsPortalAddLessonOfferComponent } from './extra-lessons-portal/components/extra-lessons-portal-add-lesson-offer/extra-lessons-portal-add-lesson-offer.component';
import { ExtraLessonsPortalLessonOfferingsComponent } from './extra-lessons-portal/components/extra-lessons-portal-lesson-offerings/extra-lessons-portal-lesson-offerings.component';
import { ExtraLessonsPortalDeleteLessonOfferComponent } from './extra-lessons-portal/components/extra-lessons-portal-delete-lesson-offer/extra-lessons-portal-delete-lesson-offer.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { ChangePasswordDialogComponent } from './shared/components/change-password-dialog/change-password-dialog.component';
import { ImpressumComponent } from './shared/components/impressum/impressum.component';
import { DatenschutzComponent } from './shared/components/datenschutz/datenschutz.component';
import { AdminPortalComponent } from './admin-portal/components/admin-portal/admin-portal.component';
import { AdminPortalAddUserComponent } from './admin-portal/components/admin-portal-add-user/admin-portal-add-user.component';
import { AdminPortalUserComponent } from './admin-portal/components/admin-portal-user/admin-portal-user.component';
import { AdminPortalUserEditDialogComponent } from './admin-portal/components/admin-portal-user-edit-dialog/admin-portal-user-edit-dialog.component';
import { AdminPortalUserPasswordDialogComponent } from './admin-portal/components/admin-portal-user-password-dialog/admin-portal-user-password-dialog.component';
import { AdminPortalUserDeleteDialogComponent } from './admin-portal/components/admin-portal-user-delete-dialog/admin-portal-user-delete-dialog.component';
import { AdminPortalExtraLessonOffersComponent } from './admin-portal/components/admin-portal-extra-lesson-offers/admin-portal-extra-lesson-offers.component';
import { AdminPortalExtraLessonOffersDeleteDialogComponent } from './admin-portal/components/admin-portal-extra-lesson-offers-delete-dialog/admin-portal-extra-lesson-offers-delete-dialog.component';

// Material imports
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    LoginComponent,
    ExtraLessonsPortalHomeComponent,
    ExtraLessonsPortalSubjectsComponent,
    ExtraLessonsPortalStudentsComponent,
    ExtraLessonsPortalOfferLessonsComponent,
    ExtraLessonsPortalAddLessonOfferComponent,
    ExtraLessonsPortalLessonOfferingsComponent,
    ExtraLessonsPortalDeleteLessonOfferComponent,
    FooterComponent,
    ChangePasswordDialogComponent,
    ImpressumComponent,
    DatenschutzComponent,
    AdminPortalComponent,
    AdminPortalAddUserComponent,
    AdminPortalUserComponent,
    AdminPortalUserEditDialogComponent,
    AdminPortalUserPasswordDialogComponent,
    AdminPortalUserDeleteDialogComponent,
    AdminPortalExtraLessonOffersComponent,
    AdminPortalExtraLessonOffersDeleteDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatExpansionModule,
    MatSelectModule,
    MatDialogModule,
    MatIconModule,
    MatSnackBarModule,
    MatToolbarModule
  ],
  providers: [NavComponent, FooterComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
