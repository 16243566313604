import { Component, OnInit } from '@angular/core';
import { SUBJECTS } from 'src/app/shared/data/subjects';
import { Subject } from '../../models/subject';

@Component({
  selector: 'app-extra-lessons-portal-subjects',
  templateUrl: './extra-lessons-portal-subjects.component.html',
  styleUrls: ['./extra-lessons-portal-subjects.component.scss']
})
export class ExtraLessonsPortalSubjectsComponent implements OnInit {

  // subjects array
  subjects: Subject[] = SUBJECTS;

  constructor() { }

  ngOnInit(): void {
  }

}
