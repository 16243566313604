<form #addUserNgForm="ngForm" [formGroup]="addUserForm" (ngSubmit)="onFormSubmit(addUserNgForm)">
    <!-- Name of new user -->
    <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" [errorStateMatcher]="matcher">
        <mat-error>
            <span *ngIf="!addUserForm.get('name').valid && addUserForm.get('name').touched">Bitte Name des neuen Benutzers eingeben</span>
        </mat-error>
    </mat-form-field>

    <!-- Class of new user -->
    <mat-form-field appearance="outline">
        <mat-label>Klasse oder Personal</mat-label>
        <mat-select formControlName="class">
          <mat-option *ngFor="let class of classes" [value]="class">
            {{ class }}
          </mat-option>
        </mat-select>
        <mat-error>
            <span *ngIf="!addUserForm.get('class').valid && addUserForm.get('class').touched">Bitte Klasse des neuen Benutzers eingeben</span>
        </mat-error>
    </mat-form-field>

    <!-- Portal id of new user -->
    <div class="row">
        <div class="col-10">
          <mat-form-field appearance="outline">
            <mat-label>Portal-ID</mat-label>
            <input matInput formControlName="portal_id" [errorStateMatcher]="matcher">
            <mat-error>
              <span *ngIf="!addUserForm.get('portal_id').valid && addUserForm.get('portal_id').touched">Bitte Portal-ID eingeben</span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-2">
          <button type="button" class="d-inline" mat-icon-button (click)="generatePortalId()" color="primary" aria-label="Refresh button">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
    </div>

    <div class="row">
        <div class="col-10">
          <mat-form-field appearance="outline">
            <mat-label>Passwort</mat-label>
            <input matInput minlength="8" formControlName="password" [errorStateMatcher]="matcher">
            <mat-error>
              <span *ngIf="!addUserForm.get('password').valid && addUserForm.get('password').touched">Bitte Passwort eingeben</span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-2">
          <button type="button" class="d-inline" mat-icon-button (click)="generatePassword(8)" color="primary" aria-label="Refresh button">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
    </div>

    <!-- Role of new user -->
    <mat-form-field appearance="outline">
        <mat-label>Rolle</mat-label>
        <mat-select formControlName="role">
          <mat-option *ngFor="let role of roles" [value]="role.id">
            {{ role.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          <span *ngIf="!addUserForm.get('role').valid && addUserForm.get('role').touched">Bitte Rolle auswählen</span>
        </mat-error>
    </mat-form-field>
    

    <!-- Submit button and messages -->
    <button class="mb-3 py-1" mat-raised-button type="submit" [disabled]="!addUserForm.valid || isLoading" color="primary">Konto erstellen</button>
    <p class="loading" *ngIf="isLoading">Benutzer wird erstellt...</p>
    <p class="success" *ngIf="successMessage">{{ successMessage }}</p>
    <p class="error" *ngIf="errorMessage">{{ errorMessage }}</p>
</form>