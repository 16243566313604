<div class="tablecontainer mat-elevation-z8 mt-3">
  <table mat-table [dataSource]="offersDataSource">
    <!-- Display offers -->

    <!-- Poral-ID of offer -->
    <ng-component matColumnDef="portal_id">
      <th mat-header-cell *matHeaderCellDef [innerHTML]="'Poral-ID'"></th>
      <td mat-cell *matCellDef="let offer">{{offer.user_portal_id}}</td>
    </ng-component>

    <!-- Name of offer -->
    <ng-component matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef [innerHTML]="'Name'"></th>
      <td mat-cell *matCellDef="let offer">{{offer.user_name}}</td>
    </ng-component>

    <!-- Class of offer -->
      <ng-component matColumnDef="class">
      <th mat-header-cell *matHeaderCellDef [innerHTML]="'Klasse / Personal'"></th>
      <td mat-cell *matCellDef="let offer">{{offer.user_class}}</td>
    </ng-component>

    <!-- Class of offer -->
    <ng-component matColumnDef="porta">
      <th mat-header-cell *matHeaderCellDef [innerHTML]="'Klasse / Personal'"></th>
      <td mat-cell *matCellDef="let offer">{{offer.user_class}}</td>
    </ng-component>
  
    <!-- Subject of offer -->
    <ng-component matColumnDef="subject">
      <th mat-header-cell *matHeaderCellDef [innerHTML]="'Fach'"></th>
      <td mat-cell *matCellDef="let offer">{{offer.subject}}</td>
    </ng-component>

    <!-- Years of offer -->
    <ng-component matColumnDef="years">
      <th mat-header-cell *matHeaderCellDef [innerHTML]="'Klassenstufen'"></th>
      <td mat-cell *matCellDef="let offer">{{offer.years}}</td>
    </ng-component>

    <!-- Phone of offer -->
    <ng-component matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef [innerHTML]="'Telefonnummer'"></th>
      <td mat-cell *matCellDef="let offer"><a [href]="'tel:' + offer.phone"><u>{{offer.phone}}</u></a></td>
    </ng-component>

    <!-- Wage of offer -->
    <ng-component matColumnDef="wage">
      <th mat-header-cell *matHeaderCellDef [innerHTML]="'Stundenlohn'"></th>
      <td mat-cell *matCellDef="let offer">{{offer.wage}}</td>
    </ng-component>

    <!-- Delete of offer -->
    <ng-component matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef [innerHTML]="'Löschen'"></th>
      <td mat-cell *matCellDef="let offer">
        <button mat-icon-button >
          <mat-icon aria-hidden="false" (click)="openDialog(offer.id)" color="warn">delete</mat-icon>
        </button>
      </td>
    </ng-component>

    <tr mat-header-row *matHeaderRowDef="offersTableDisplayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: offersTableDisplayedColumns"></tr>
  </table>
</div>