import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-extra-lessons-portal-offer-lessons',
  templateUrl: './extra-lessons-portal-offer-lessons.component.html',
  styleUrls: ['./extra-lessons-portal-offer-lessons.component.scss']
})
export class ExtraLessonsPortalOfferLessonsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
