<div class="box px-3">
    <mat-accordion>
        <!-- Offer and manage extra lesson offerings -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Neues Nachhilfeangebot erstellen
                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-extra-lessons-portal-add-lesson-offer></app-extra-lessons-portal-add-lesson-offer>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Meine Nachhilfeangebote
                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-extra-lessons-portal-lesson-offerings></app-extra-lessons-portal-lesson-offerings>
        </mat-expansion-panel>
    </mat-accordion>
    <a class="text-dark" [routerLink]="['/']" routerLinkActive="router-link-active">ZURÜCK</a>
</div>