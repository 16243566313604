import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { deleteDoc, doc } from 'firebase/firestore';
import { firestore } from 'src/firebase';

@Component({
  selector: 'app-admin-portal-extra-lesson-offers-delete-dialog',
  templateUrl: './admin-portal-extra-lesson-offers-delete-dialog.component.html',
  styleUrls: ['./admin-portal-extra-lesson-offers-delete-dialog.component.scss']
})
export class AdminPortalExtraLessonOffersDeleteDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  async removeOffer() {
    // delete doc user
    await deleteDoc(doc(firestore, 'extra_lesson_offers', this.data.id));
  }

  ngOnInit(): void {
  }

}
