<div class="p-3">
    <div class="row">
        <div class="col-6 col-md-3" *ngFor="let subject of subjects">
            <button [routerLink]="['/fach/', subject.id]" routerLinkActive="router-link-active"  mat-raised-button color="primary">{{subject.name}}</button>
        </div>
    </div>
    <a class="text-dark" [routerLink]="['/']" routerLinkActive="router-link-active">ZURÜCK</a>
</div>


