<div class="box px-3">
    <mat-accordion>
        <!-- Add users (students and teachers) -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Schüler oder Personal hinzufügen
                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-admin-portal-add-user></app-admin-portal-add-user>
        </mat-expansion-panel>
        <!-- Manager users (students and teachers) -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Schüler oder Personal
                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-admin-portal-user></app-admin-portal-user>
        </mat-expansion-panel>
        <!-- Manager offers -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Nachhilfeangebote
                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-admin-portal-extra-lesson-offers></app-admin-portal-extra-lesson-offers>
        </mat-expansion-panel>
    </mat-accordion>
</div>