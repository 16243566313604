import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { httpsCallable } from 'firebase/functions';
import { MyErrorStateMatcher } from 'src/app/shared/functions/errorStateMatcher';
import { functions } from 'src/firebase';

@Component({
  selector: 'app-admin-portal-user-password-dialog',
  templateUrl: './admin-portal-user-password-dialog.component.html',
  styleUrls: ['./admin-portal-user-password-dialog.component.scss']
})
export class AdminPortalUserPasswordDialogComponent implements OnInit {

  // loading animatation indicatar indicator variable
  isLoading: boolean = false;

  // success and error message
  successMessage: any;
  errorMessage: any;

  changePasswordForm: FormGroup;
  matcher = new MyErrorStateMatcher();

  // password
  hide: boolean = false;

  // firebase functions
  changeUserPassword = httpsCallable(functions, 'changeUserPassword');

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group({
      newPassword : [null, Validators.required],
      newPassword2 : [null, Validators.required]
    });
  }

  async updateUserPassword(password: string, form: NgForm) {
    try {
      await this.changeUserPassword({id: this.data.id, new_password: password});
      // clear form
      form.resetForm();
      // display success
      this.successMessage = 'Passwort geändert.';
    } catch (error) {
      console.log(error);
      // display error
      this.errorMessage = 'Fehler beim geändert.';
    }
  }

  async onFormSubmit(form: NgForm) {
    // enable loading
    this.isLoading = true;

    // clear messages
    this.successMessage = this.errorMessage = '';

    if (this.changePasswordForm.value.newPassword !== this.changePasswordForm.value.newPassword2) {
      // passwords don't match
      this.errorMessage = 'Die beiden neuen Passwörter stimmen nicht überein.';
    } else {
      // update password
      await this.updateUserPassword(this.changePasswordForm.value.newPassword, form);
    }

    // disable loading
    this.isLoading = false;
  }
}
