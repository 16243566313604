import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { collection, onSnapshot, query, Unsubscribe } from 'firebase/firestore';
import { firestore } from 'src/firebase';
import { ROLES } from 'src/app/shared/data/roles';
import { AdminPortalUserEditDialogComponent } from '../admin-portal-user-edit-dialog/admin-portal-user-edit-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AdminPortalUserDeleteDialogComponent } from '../admin-portal-user-delete-dialog/admin-portal-user-delete-dialog.component';
import { AdminPortalUserPasswordDialogComponent } from '../admin-portal-user-password-dialog/admin-portal-user-password-dialog.component';

@Component({
  selector: 'app-admin-portal-user',
  templateUrl: './admin-portal-user.component.html',
  styleUrls: ['./admin-portal-user.component.scss']
})
export class AdminPortalUserComponent implements OnInit, OnDestroy {

  users: any;
  
  // loading animatation indicatar indicator variable
  isLoading: boolean = false;

  // angular material users table setup
  usersTableDisplayedColumns: string[] = ['portal_id', 'name', 'class', 'role', 'edit', 'changePassword', 'delete'];
  usersDataSource = new MatTableDataSource();

  // unsubscribs
  unsubscribs: Unsubscribe;

  // roles
  roles = ROLES;

  constructor(private dialog: MatDialog) { }

  getUser() {
    try {
      // define query
      const q = query(collection(firestore, 'users'));
      // listen to realtime updates
      this.unsubscribs = onSnapshot(q, (querySnapshot) => {
        this.users = [];
        querySnapshot.forEach((doc) => {
          let user = doc.data();
          user.id = doc.id;
          this.users.push(user);
        });
        // update users table
        this.usersDataSource = new MatTableDataSource(this.users);
      });
    } catch (error) {
      this.unsubscribs();
      console.log(error);
    }
  }

  ngOnInit() {
    // enable loading
    this.isLoading = true;

    // start real time snapshot of users
    this.getUser();

    // disable loading
    this.isLoading = false;
  }

  ngOnDestroy(): void {
    this.unsubscribs();
  }

  openDialogEdit(user_id: any) {
    this.dialog.open(AdminPortalUserEditDialogComponent, {
      width: '500px',
      data: {
        id: user_id
      }
    });
  }

  openDialogChangePassword(user_id: any) {
    this.dialog.open(AdminPortalUserPasswordDialogComponent, {
      width: '400px',
      data: {
        id: user_id
      }
    });
  }

  openDialogDelete(user_id: any) {
    this.dialog.open(AdminPortalUserDeleteDialogComponent, {
      width: '400px',
      data: {
        id: user_id
      }
    });
  }
}
