export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyAbNAuiR7b1uHAHWsswWlutUlgomrhJ6bA',
    authDomain: 'nachhilfeportal.firebaseapp.com',
    projectId: 'nachhilfeportal',
    storageBucket: 'nachhilfeportal.appspot.com',
    messagingSenderId: '778525227561',
    appId: '1:778525227561:web:3f783cfa3c2bfc803e3a38'
  },
  appCheckReCaptchaSiteKey: '6LdeXEwiAAAAAFUdpBtQszIs3xfudfhlb9V_ULGQ', 
  emailDomain: 'nachhilfe-portal.com'
};