<div class="text-dark">
  <div class="h2" mat-dialog-title>Editieren</div>
  <mat-dialog-content class="py-3">

    <!-- Portal id of user -->
    <mat-form-field appearance="outline">
      <mat-label>Portal-ID</mat-label>
      <input matInput [(ngModel)]="user.portal_id" disabled cdkFocusInitial>
    </mat-form-field>

    <!-- Name of new user -->
    <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="user.name" (change)="updateUser()">
    </mat-form-field>

    <!-- Class of new user -->
    <mat-form-field appearance="outline">
        <mat-label>Klasse oder Personal</mat-label>
        <mat-select [(ngModel)]="user.class" (change)="updateUser()">
          <mat-option *ngFor="let class of classes" [value]="class">
            {{ class }}
          </mat-option>
        </mat-select>
    </mat-form-field>

    <!-- Role of new user -->
    <mat-form-field appearance="outline">
        <mat-label>Rolle</mat-label>
        <mat-select [(ngModel)]="user.role" disabled>
          <mat-option *ngFor="let role of roles" [value]="role.id">
            {{ role.name }}
          </mat-option>
        </mat-select>
    </mat-form-field>
      
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close>Zurück</button>
    <button mat-raised-button (click)="updateUser()" mat-dialog-close color="primary">Editieren</button>
  </mat-dialog-actions>
</div>


