import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { collection, onSnapshot, query, where } from '@firebase/firestore';
import { SUBJECTS } from 'src/app/shared/data/subjects';
import { applyFilter } from 'src/app/shared/functions/tableFiltering';
import { FirebaseAuthService } from 'src/app/shared/services/firebase-auth.service';
import { auth, firestore } from 'src/firebase';
import { ExtraLessonsPortalDeleteLessonOfferComponent } from '../extra-lessons-portal-delete-lesson-offer/extra-lessons-portal-delete-lesson-offer.component';

@Component({
  selector: 'app-extra-lessons-portal-lesson-offerings',
  templateUrl: './extra-lessons-portal-lesson-offerings.component.html',
  styleUrls: ['./extra-lessons-portal-lesson-offerings.component.scss']
})
export class ExtraLessonsPortalLessonOfferingsComponent implements OnInit {

  // angular material table setup
  displayedColumns: string[] = ['subject', 'years', 'phone', 'wage', 'remove'];
  dataSource = new MatTableDataSource();

  // offerings array
  offerings: any[] = [];

  subjects = SUBJECTS;

  constructor(private dialog: MatDialog, private firebaseAuth: FirebaseAuthService) { }

  async getLessonOfferings(user_id: any) {
    try {
      // get offerings from firestore
      const q = query(collection(firestore, 'extra_lesson_offers'), where('user_id', '==', user_id));
      onSnapshot(q, (querySnapshot) => {
        this.offerings = [];
        querySnapshot.forEach((offeringDoc) => {
          let offer = offeringDoc.data();
          // format subject
          offer.subject = this.subjects.find((x: any) => x.id === offer.subject).name;
          offer.id = offeringDoc.id;
          this.offerings.push(offer);
        });
        this.dataSource = new MatTableDataSource(this.offerings);
      });
    } catch (error) {
      console.log(error);
    }
  }

  async extract() {
    // get current user id
    const user = await this.firebaseAuth.getCurrentUser(auth);
    // get offerings from db
    this.getLessonOfferings(user);
  }

  ngOnInit(): void {
    this.extract();
  }

  // remove offer dialog
  openDeleteLessonOfferDialog(id: string) {
    // open dialog
    this.dialog.open(ExtraLessonsPortalDeleteLessonOfferComponent, {
      data: { id: id },
      maxWidth: '1100px'
    });
  }

  // table filtering
  filterDataSource(event: Event) {
    this.dataSource.filter = applyFilter(event);
  }
}
