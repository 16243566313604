import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserPortalAuthGuardService {

  constructor(private router: Router) { }

  // can activate function for user protected routes
  canActivate() {
    // get user from local storage
    const user = localStorage.getItem('user');
    // check if user is logged in
    if (JSON.parse(user) == null) {
      // redirect to login
      this.router.navigate(['login']);
      return false;
    } else {
      return true;
    }
  }
}
