export const CLASSES: any[] = [
    '5a',
    '5b',
    '5c',
    '5d',
    '6a',
    '6b',
    '6c',
    '6d',
    '7a',
    '7b',
    '7c',
    '8a',
    '8b',
    '8c',
    '9a',
    '9b',
    '9c',
    '9d',
    '10a',
    '10b',
    '10c',
    '10d',
    'Q11',
    'Q12',
    'Personal'
];