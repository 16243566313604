import { Component, OnInit } from '@angular/core';
import { FormBuilder, NgForm, Validators } from '@angular/forms';
import { functions } from './../../../../firebase';
import { httpsCallable } from "firebase/functions";
import { CLASSES } from 'src/app/shared/data/classes';
import { ROLES } from 'src/app/shared/data/roles';
import { MyErrorStateMatcher } from 'src/app/shared/functions/errorStateMatcher';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-portal-add-user',
  templateUrl: './admin-portal-add-user.component.html',
  styleUrls: ['./admin-portal-add-user.component.scss']
})
export class AdminPortalAddUserComponent implements OnInit {

  // form variable
  addUserForm: any;

  // classes and roles
  classes = CLASSES;
  roles = ROLES;

  // success and error messages variables
  successMessage: string = '';
  errorMessage: string = '';

  // error state matcher for form
  matcher = new MyErrorStateMatcher();

  // loading variable to prevent duplicated api requests
  isLoading: boolean = false;

  // firebase functions
  createUserAccount = httpsCallable(functions, 'createUser');

  constructor(private formBuilder: FormBuilder) {}

  setupForm() {
    this.addUserForm = this.formBuilder.group({
      name: [null, Validators.required],
      class: [null, Validators.required],
      portal_id: [null, Validators.required],
      password: [null, Validators.required],
      role: [ROLES[0].id, Validators.required]
    });
    this.generatePortalId();
    this.generatePassword(8);
  }

  ngOnInit(): void {
    // setup form
    this.setupForm();
  }

  // get a random integer between minimum and maximum
  getRndInteger(minimum: number, maximum: number) {
    return Math.floor(Math.random() * (maximum - minimum)) + minimum;
  }

  // generate shop id
  generatePortalId() {
    // pass random shop id to form
    this.addUserForm.patchValue({
      portal_id: this.getRndInteger(99999, 10000)
    });
  }

  generatePassword(length: number) {
    // define charset
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNPQRSTUVWXYZ123456789';
    let generatedPassword = '';

    // generate password
    for (let i = 0, n = charset.length; i < length; ++i) {
        generatedPassword += charset.charAt(Math.floor(Math.random() * n));
    }

    // fill in password to form
    this.addUserForm.patchValue({
      password: generatedPassword
    });
  }

  // create user account in firebase authentication
  async createUser(user: any, form: NgForm) {
    try {
      // create user in authentication and create doc in users collection for user
      await this.createUserAccount(user);
      // display success
      this.successMessage = 'Benutzer erstellt.';
      // clear form
      this.clearForm(form);
      // pass current class and role
      this.addUserForm.patchValue({
        class: user.class,
        role: user.role
      });
      // generate shop id and password
      this.generatePortalId();
      this.generatePassword(8);
    } catch (error) {
      console.log(error);
      // display error message
      this.errorMessage = error.message;
    }
  }

  async onFormSubmit(form: NgForm) {
    // enable loading
    this.isLoading = true;

    // clear messages
    this.successMessage = this.errorMessage = '';

    // retrieve form data
    let user = this.addUserForm.value;
    
    // create user account
    await this.createUser(user, form);

    // disable loading
    this.isLoading = false;
  }

  // reset and clear form
  clearForm(form: NgForm) {
    form.form.markAsPristine();
    form.resetForm();
  }
}

