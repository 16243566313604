import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { deleteDoc, doc } from '@firebase/firestore';
import { firestore } from 'src/firebase';

@Component({
  selector: 'app-extra-lessons-portal-delete-lesson-offer',
  templateUrl: './extra-lessons-portal-delete-lesson-offer.component.html',
  styleUrls: ['./extra-lessons-portal-delete-lesson-offer.component.scss']
})
export class ExtraLessonsPortalDeleteLessonOfferComponent implements OnInit {

  // loading variable to prevent duplicated api requests
  isLoading: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  async deleteLessonOffer(id: string) {
    // enable loading
    this.isLoading = true;
    try {
      // delete lesson offer in firestore
      await deleteDoc(doc(firestore, 'extra_lesson_offers', id));
    } catch (error) {
      console.log(error);
    }
  }

}
