<div class="tablecontainer mat-elevation-z8">
    <table mat-table [dataSource]="dataSource">
        <!-- Display offer -->
        <!-- Offer subject -->
        <ng-container matColumnDef="subject">
            <th mat-header-cell *matHeaderCellDef>Fach</th>
            <td mat-cell *matCellDef="let offer">{{offer.subject}}</td>
        </ng-container>

        <!-- Offer years -->
        <ng-container matColumnDef="years">
            <th mat-header-cell *matHeaderCellDef>Jahrgangsstufen</th>
            <td mat-cell *matCellDef="let offer">{{offer.years}}</td>
        </ng-container>

        <!-- Offer phone number -->
        <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef>Telefonnummer</th>
            <td mat-cell *matCellDef="let offer"><a [href]="'tel:' + offer.phone"><u>{{offer.phone}}</u></a></td>
        </ng-container>

        <!-- Offer wage -->
        <ng-container matColumnDef="wage">
            <th mat-header-cell *matHeaderCellDef>Stundenlohn</th>
            <td mat-cell *matCellDef="let offer">{{offer.wage}} €</td>
        </ng-container>

        <!-- Remove offer -->
        <ng-container matColumnDef="remove">
            <th mat-header-cell *matHeaderCellDef>Löschen</th>
            <td mat-cell *matCellDef="let offer">
                <button mat-icon-button (click)="openDeleteLessonOfferDialog(offer.id)" color="warn" aria-label="Remove offer">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>

    