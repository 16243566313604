// Firebase setup and initialization
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { connectAuthEmulator } from '@firebase/auth';
import { connectFunctionsEmulator, getFunctions } from '@firebase/functions';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { environment } from './environments/environment';

// Initialize Firebase
const app = initializeApp(environment.firebaseConfig);

if (location.hostname !== 'localhost') {
    // Initialize app check (recaptcha token depends on environment)
    initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider(environment.appCheckReCaptchaSiteKey),
        isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
    });
}

// Initalize firebase products instance
export const auth = getAuth(); // firebase auth
export const firestore = getFirestore(); // firestore
export const functions = getFunctions(app, 'europe-west3'); // firebase functions

// use firestore emulator if hostname is localhost
if (location.hostname === 'localhost') {
    connectFirestoreEmulator(getFirestore(), 'localhost', 8080);
    connectAuthEmulator(auth, 'http://localhost:9099');
    connectFunctionsEmulator(getFunctions(app, 'europe-west3'), 'localhost', 5001);
}